import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">


<path d="M5925 7513 c-332 -17 -679 -74 -870 -142 -27 -10 -88 -32 -135 -49
-47 -16 -107 -40 -135 -52 -27 -12 -66 -28 -85 -37 -44 -19 -233 -114 -280
-140 -120 -68 -366 -233 -414 -278 -6 -5 -56 -49 -111 -96 -188 -163 -386
-383 -524 -584 -60 -88 -161 -253 -161 -265 0 -5 -6 -15 -13 -22 -24 -24 -187
-381 -187 -410 0 -8 -4 -18 -8 -23 -5 -6 -19 -43 -32 -83 -32 -103 -24 -185
24 -251 55 -74 102 -101 194 -108 65 -5 86 -2 124 15 49 21 97 65 116 106 26
55 33 72 41 101 10 30 29 82 73 190 88 216 308 558 463 719 17 17 51 54 76 82
26 28 60 61 75 73 16 13 52 44 79 70 78 72 129 112 281 214 247 166 587 316
859 378 289 67 618 91 870 63 155 -17 324 -42 339 -52 6 -3 28 -9 51 -13 38
-7 78 -17 210 -54 128 -36 242 17 301 141 33 70 32 132 -5 210 -44 93 -110
138 -256 174 -16 4 -64 17 -105 28 -41 11 -118 27 -170 36 -52 9 -115 21 -140
26 -80 17 -434 39 -545 33z"/>
<path d="M5289 6761 c-20 -4 -57 -20 -82 -34 -82 -49 -127 -128 -127 -225 0
-67 12 -106 70 -228 58 -121 57 -122 -20 -162 l-65 -32 -49 16 c-154 53 -346
-76 -346 -232 0 -39 93 -261 310 -739 54 -120 70 -159 70 -171 0 -8 -22 -25
-50 -39 -46 -23 -50 -24 -58 -7 -5 9 -89 197 -187 417 -98 220 -185 410 -193
423 -78 119 -352 54 -422 -99 -32 -72 -36 -56 90 -339 21 -47 50 -112 65 -145
31 -68 128 -286 187 -420 22 -49 46 -103 54 -120 7 -16 58 -131 113 -254 55
-123 108 -232 118 -243 112 -124 424 8 424 180 0 22 -18 78 -44 137 -95 211
-137 308 -137 311 0 10 104 54 110 47 4 -4 22 -42 40 -83 18 -41 46 -106 64
-145 18 -38 48 -105 67 -149 121 -276 144 -320 188 -351 36 -25 54 -30 116
-33 63 -4 81 0 127 21 67 32 109 70 137 124 33 65 28 118 -23 236 -47 111
-322 731 -360 812 -13 28 -65 145 -116 260 -51 116 -117 264 -146 329 -30 65
-54 125 -54 132 0 12 76 54 98 54 5 0 121 -251 257 -557 136 -307 260 -585
275 -619 16 -33 54 -119 85 -190 60 -135 116 -261 170 -379 55 -121 139 -180
255 -180 190 1 322 200 245 370 -7 17 -56 127 -108 245 -114 259 -92 209 -321
725 -103 230 -199 444 -213 475 -28 61 -35 78 -89 200 -20 47 -52 118 -70 158
-19 41 -34 75 -34 77 0 2 -18 43 -40 90 -22 47 -40 88 -40 90 0 21 -69 143
-97 171 -62 63 -160 93 -244 75z"/>
<path d="M6722 3780 c-143 -30 -232 -126 -232 -250 0 -136 66 -202 285 -285
66 -25 131 -53 144 -63 32 -23 41 -59 27 -102 -16 -50 -67 -67 -154 -52 -78
13 -103 34 -111 94 l-6 43 -109 3 -109 3 7 -57 c10 -88 49 -147 132 -200 147
-94 382 -83 500 23 52 46 69 88 69 173 0 64 -4 82 -27 122 -49 82 -109 120
-288 185 -106 38 -150 72 -150 115 0 58 87 101 166 82 36 -8 84 -52 84 -75 0
-46 7 -49 117 -49 l106 0 -7 46 c-13 97 -69 172 -164 219 -43 22 -71 27 -145
30 -51 2 -111 0 -135 -5z"/>
<path d="M2160 3319 l0 -461 233 4 c208 4 238 7 287 27 112 44 164 121 164
241 0 87 -17 129 -72 177 l-36 32 28 28 c46 46 60 82 60 158 0 105 -38 167
-129 213 -67 33 -138 42 -340 42 l-195 0 0 -461z m422 261 c30 -23 41 -68 27
-109 -17 -49 -47 -61 -149 -61 l-90 0 0 95 0 95 94 0 c78 0 97 -3 118 -20z
m22 -349 c26 -21 39 -86 26 -127 -18 -55 -41 -64 -157 -64 l-103 0 0 106 0
106 109 -4 c71 -2 114 -8 125 -17z"/>
<path d="M2952 3428 l3 -353 27 -50 c35 -65 55 -86 105 -118 88 -56 219 -71
336 -38 82 22 139 60 179 119 53 77 58 115 58 470 l0 322 -110 0 -109 0 -3
-344 -3 -344 -23 -25 c-48 -51 -161 -56 -208 -10 -41 41 -44 70 -44 403 l0
320 -105 0 -106 0 3 -352z"/>
<path d="M3770 3320 l0 -460 105 0 105 0 2 273 3 272 58 -95 c32 -52 62 -102
66 -110 67 -116 197 -327 205 -333 6 -4 54 -6 106 -5 l95 3 3 458 2 457 -110
0 -110 0 -2 -269 -3 -268 -160 268 -160 268 -102 1 -103 0 0 -460z"/>
<path d="M4640 3319 l0 -461 193 4 c166 4 200 8 247 26 127 50 215 152 250
288 59 230 -23 459 -197 551 -82 44 -141 53 -328 53 l-165 0 0 -461z m377 265
c82 -34 119 -134 110 -294 -6 -109 -19 -153 -56 -193 -40 -44 -74 -57 -146
-57 l-65 0 0 280 0 280 60 0 c33 0 77 -7 97 -16z"/>
<path d="M5340 3773 c0 -4 8 -21 18 -38 10 -16 78 -145 150 -286 l132 -257 0
-166 0 -166 104 0 c85 0 105 3 110 16 3 9 6 88 6 176 l0 160 74 141 c40 78
105 203 145 279 39 75 71 139 71 142 0 3 -52 6 -115 6 l-115 0 -14 -32 c-13
-33 -28 -65 -113 -247 -26 -57 -47 -91 -52 -85 -5 5 -45 88 -89 184 l-79 175
-116 3 c-65 1 -117 -1 -117 -5z"/>
<path d="M7250 3463 c1 -353 7 -400 59 -477 40 -58 89 -91 170 -116 76 -24
162 -25 243 -4 97 25 161 75 206 159 l27 50 3 353 3 352 -105 0 -105 0 -3
-332 c-3 -315 -4 -335 -24 -368 -38 -65 -165 -74 -223 -16 l-26 26 -5 343 -5
342 -107 3 -108 3 0 -318z"/>
<path d="M8072 3323 l3 -458 230 0 c247 1 268 4 348 54 65 42 97 102 104 193
5 66 3 79 -20 125 -15 28 -41 62 -58 76 l-33 25 35 35 c81 81 79 226 -4 314
-72 75 -122 87 -385 91 l-222 4 2 -459z m412 266 c55 -26 57 -126 2 -160 -25
-16 -46 -19 -116 -17 l-85 3 -3 93 -3 92 90 0 c49 0 101 -5 115 -11z m16 -354
c20 -10 33 -28 41 -54 13 -45 7 -78 -20 -112 -18 -23 -26 -24 -128 -24 l-108
0 -2 95 c0 52 1 98 4 103 8 14 182 8 213 -8z"/>
<path d="M4530 2580 l0 -160 25 0 25 0 0 160 0 160 -25 0 -25 0 0 -160z"/>
<path d="M6990 2580 l0 -160 25 0 25 0 0 160 0 160 -25 0 -25 0 0 -160z"/>
<path d="M2396 2678 c15 -29 37 -69 50 -89 16 -27 24 -55 26 -101 3 -60 4 -63
28 -63 24 0 25 3 28 63 2 46 10 74 26 101 13 20 35 60 50 89 l26 52 -27 0
c-24 0 -33 -9 -62 -65 -18 -36 -37 -65 -41 -65 -5 0 -23 29 -42 65 -28 57 -36
65 -60 65 l-28 0 26 -52z"/>
<path d="M3340 2574 l0 -156 86 3 c76 4 88 7 105 28 23 28 25 93 4 111 -16 14
-20 40 -6 40 15 0 22 47 11 75 -13 35 -65 55 -140 55 l-60 0 0 -156z m148 72
c4 -35 -10 -46 -60 -46 -38 0 -38 0 -38 41 l0 40 48 -3 c44 -3 47 -5 50 -32z
m-7 -96 c12 -7 19 -21 19 -40 0 -35 -23 -50 -74 -50 -36 0 -36 0 -36 43 0 24
3 47 7 50 10 11 62 8 84 -3z"/>
<path d="M4275 2718 c-3 -8 -4 -77 -3 -153 l3 -140 25 0 c23 0 25 4 30 54 l5
55 50 2 c59 3 89 18 104 51 18 39 13 71 -18 105 -28 31 -33 33 -110 36 -63 3
-82 1 -86 -10z m159 -54 c40 -39 7 -84 -62 -84 l-47 0 0 50 0 50 47 0 c29 0
52 -6 62 -16z"/>
<path d="M5210 2575 l0 -155 25 0 c24 0 25 2 25 65 l0 65 60 0 c53 0 60 2 60
20 0 18 -7 20 -60 20 l-60 0 0 45 0 45 70 0 c68 0 70 1 70 25 0 25 0 25 -95
25 l-95 0 0 -155z"/>
<path d="M5910 2575 l0 -155 64 0 c108 0 156 47 156 155 0 108 -52 155 -172
155 l-48 0 0 -155z m149 79 c24 -30 29 -106 10 -147 -13 -28 -48 -47 -84 -47
l-25 0 0 110 0 110 39 0 c31 0 44 -6 60 -26z"/>
<path d="M6177 2717 c-8 -21 8 -39 29 -35 27 5 25 42 -2 46 -12 2 -24 -3 -27
-11z"/>
<path d="M6514 2715 c-4 -9 -2 -21 4 -27 16 -16 47 -5 47 17 0 26 -42 34 -51
10z"/>
<path d="M7258 2713 c-16 -10 -35 -32 -43 -49 -19 -41 -19 -146 -1 -183 42
-80 160 -85 210 -8 37 55 27 171 -17 223 -30 35 -108 44 -149 17z m113 -50
c36 -31 31 -159 -8 -188 -30 -23 -69 -18 -92 11 -16 20 -21 41 -21 86 0 33 5
68 11 79 20 39 74 45 110 12z"/>
<path d="M4040 2684 c0 -17 -7 -28 -20 -31 -11 -3 -20 -14 -20 -24 0 -12 7
-19 20 -19 18 0 20 -7 20 -83 0 -93 7 -107 57 -107 26 0 33 4 33 20 0 13 -7
20 -20 20 -18 0 -20 7 -20 75 0 68 2 75 20 75 13 0 20 7 20 20 0 13 -7 20 -19
20 -14 0 -21 8 -23 27 -2 18 -10 27 -25 29 -19 3 -23 -1 -23 -22z"/>
<path d="M6630 2680 c0 -23 -4 -30 -20 -30 -13 0 -20 -7 -20 -20 0 -13 7 -20
19 -20 17 0 19 -9 22 -85 4 -93 11 -105 62 -105 20 0 27 5 27 20 0 13 -7 20
-20 20 -18 0 -20 7 -20 75 0 68 2 75 20 75 26 0 27 36 1 43 -11 2 -21 15 -23
27 -2 15 -11 24 -25 26 -20 3 -23 -1 -23 -26z"/>
<path d="M2655 2626 c-30 -30 -41 -87 -26 -137 15 -49 44 -69 99 -69 63 0 102
40 102 105 0 52 -21 99 -49 115 -36 19 -100 12 -126 -14z m107 -33 c23 -21 24
-89 1 -115 -37 -41 -93 -7 -93 57 0 63 51 95 92 58z"/>
<path d="M2860 2557 c0 -127 7 -137 106 -137 l74 0 0 115 0 115 -25 0 c-25 0
-25 -1 -25 -84 0 -91 -10 -110 -52 -104 -22 3 -23 8 -26 96 -3 90 -3 92 -27
92 -25 0 -25 -1 -25 -93z"/>
<path d="M3090 2535 l0 -115 25 0 c24 0 24 2 27 88 l3 87 33 10 c21 6 32 15
32 27 0 15 -9 18 -60 18 l-60 0 0 -115z"/>
<path d="M3622 2628 c-47 -51 -50 -141 -6 -185 32 -32 114 -33 143 -1 11 11
18 25 15 30 -7 11 -41 10 -49 -2 -12 -19 -63 -11 -79 12 -8 12 -13 25 -10 30
3 4 36 8 75 8 l69 0 0 30 c0 37 -23 85 -45 94 -37 14 -91 6 -113 -16z m96 -30
c25 -25 13 -38 -33 -38 -30 0 -45 4 -45 13 0 19 21 37 45 37 12 0 26 -5 33
-12z"/>
<path d="M3832 2634 c-26 -18 -30 -65 -7 -87 8 -9 32 -21 52 -27 53 -17 66
-29 52 -46 -18 -22 -67 -18 -74 6 -8 27 -55 28 -55 2 0 -33 42 -62 91 -62 59
0 89 22 89 64 0 40 -14 53 -72 71 -31 9 -43 17 -43 31 0 28 50 32 65 5 6 -12
20 -21 30 -21 26 0 26 34 0 60 -25 25 -95 27 -128 4z"/>
<path d="M4633 2634 c-3 -9 -3 -52 0 -96 9 -108 18 -118 114 -118 l73 0 0 115
0 115 -25 0 c-25 0 -25 -1 -25 -83 0 -87 -8 -107 -45 -107 -37 0 -45 20 -45
107 0 77 -2 83 -20 83 -11 0 -23 -7 -27 -16z"/>
<path d="M4902 2643 c-38 -15 -56 -128 -27 -182 17 -33 33 -41 86 -41 40 0 48
-7 27 -28 -15 -15 -70 -16 -85 -1 -14 14 -33 3 -33 -17 0 -22 67 -47 106 -39
62 11 74 41 74 185 l0 127 -67 1 c-38 0 -74 -2 -81 -5z m82 -39 c12 -5 16 -20
16 -59 0 -71 -7 -85 -40 -85 -37 0 -53 22 -54 74 -1 63 28 89 78 70z"/>
<path d="M5454 2627 c-23 -20 -28 -34 -32 -85 -4 -58 -3 -63 26 -92 25 -24 39
-30 74 -30 50 1 68 12 91 57 28 53 19 111 -23 153 -29 29 -100 28 -136 -3z
m110 -39 c32 -46 9 -128 -35 -128 -43 0 -59 18 -59 66 0 25 5 54 10 65 15 27
64 25 84 -3z"/>
<path d="M5660 2535 c0 -108 1 -115 20 -115 18 0 20 7 20 79 0 85 12 111 51
111 12 0 19 7 19 20 0 18 -7 20 -55 20 l-55 0 0 -115z"/>
<path d="M6180 2535 l0 -115 25 0 25 0 0 115 0 115 -25 0 -25 0 0 -115z"/>
<path d="M6305 2627 c-29 -45 -37 -83 -25 -126 16 -59 45 -83 98 -79 26 2 42
-1 42 -8 0 -21 -29 -36 -57 -31 -61 12 -67 12 -71 -4 -5 -24 61 -52 104 -45
62 12 74 41 74 186 l0 127 -75 1 c-68 2 -76 0 -90 -21z m104 -26 c17 -11 17
-107 0 -128 -14 -16 -51 -17 -67 -1 -7 7 -12 35 -12 63 0 28 5 56 12 63 14 14
47 15 67 3z"/>
<path d="M6520 2535 c0 -108 1 -115 20 -115 19 0 20 7 20 115 0 108 -1 115
-20 115 -19 0 -20 -7 -20 -115z"/>
<path d="M6782 2634 c-27 -19 -30 -54 -3 -54 10 0 24 7 31 15 17 20 66 19 74
-1 11 -29 6 -34 -39 -34 -35 0 -52 -6 -70 -25 -54 -53 -6 -132 70 -115 6 1 29
2 53 1 l42 0 0 88 c0 74 -3 93 -21 115 -17 21 -29 26 -68 26 -26 0 -56 -7 -69
-16z m108 -129 c0 -46 -82 -63 -88 -17 -4 24 22 40 66 41 17 1 22 -5 22 -24z"/>
<path d="M7505 2595 c19 -30 35 -57 35 -60 0 -4 -13 -25 -28 -48 -46 -68 -46
-67 -12 -67 25 0 33 6 47 35 9 19 20 35 24 35 4 0 16 -16 28 -35 15 -25 27
-35 46 -35 31 0 31 2 -3 53 -16 23 -31 47 -35 53 -3 6 9 34 27 63 l34 52 36
-99 c46 -126 46 -155 1 -163 -12 -3 -21 -14 -23 -27 -3 -18 1 -22 23 -22 42 0
65 37 116 184 l47 137 -26 -3 c-21 -3 -29 -13 -47 -65 -12 -35 -24 -65 -27
-68 -3 -3 -15 26 -27 65 l-22 70 -49 0 c-45 0 -51 -3 -73 -36 l-25 -35 -22 35
c-17 28 -28 36 -51 36 l-29 0 35 -55z"/>
<path d="M7924 2642 c-27 -18 -44 -59 -44 -105 0 -82 30 -118 97 -115 41 2 46
-2 36 -29 -6 -16 -57 -17 -85 -2 -33 18 -48 -6 -21 -33 32 -31 103 -31 137 1
25 24 26 28 26 156 l0 132 -67 1 c-38 1 -73 -2 -79 -6z m96 -52 c6 -12 10 -42
8 -68 -3 -41 -6 -48 -31 -56 -42 -15 -61 2 -65 57 -4 61 11 87 48 87 19 0 33
-7 40 -20z"/>
<path d="M8157 2639 c-51 -30 -64 -138 -21 -188 22 -27 32 -31 74 -31 60 0
102 28 81 54 -12 14 -15 14 -34 1 -29 -20 -60 -19 -80 3 -32 35 -21 42 59 42
l77 0 -6 37 c-10 60 -37 88 -88 90 -24 2 -52 -2 -62 -8z m94 -56 c8 -22 6 -23
-41 -23 -54 0 -55 1 -39 31 15 28 69 22 80 -8z"/>
<path d="M8330 2534 l0 -114 25 0 c24 0 25 2 25 76 0 90 10 114 49 114 36 0
41 -12 41 -111 0 -78 0 -79 25 -79 25 0 25 1 25 86 0 81 -10 120 -34 136 -6 4
-43 7 -83 6 l-73 -1 0 -113z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
